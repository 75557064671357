body {
    margin: 0;
    font-family: 'Literata', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
    zoom: 0.9;
    -moz-transform: scale(0.9);
    -moz-transform-origin: 0 0;
}

.mb32 {
    margin-bottom: 32px !important;
}

.mb24 {
    margin-bottom: 24px !important;
}

.mb16 {
    margin-bottom: 16px !important;
}

.unsetHW {
    height: unset!important;
    width: unset!important;
}

.verticalCenter{
    display: flex;
    align-items: center;
}
