.twoFactorInput {
    height: 46px;
    width: 39px;
    border-radius: 8px;
    border: solid 1px #ced4d4;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    margin-left: 4px;
    margin-right: 4px;
    box-shadow: 0 2px 1px 0 #1018280D;
}
.twoFactorInput:focus {
    outline: none 0;
}