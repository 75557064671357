.tabListElement {
    height: 22px;
    padding: 12px 16px 12px 16px;
    border: solid 1px #CED4DA;
    list-style-type: none;
    outline: none;
    border-left: none;
    cursor: pointer;
    font-size:16px;
    font-weight: 600;
    box-sizing: unset;
}

.tabListElementActive {
    background-color: #f3f5f6;
}

.tabListElement:first-child {
    border-left: solid 1px #CED4DA;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px
}

.tabListElement:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px
}

@media (max-width: 1400px) {
    .ml-xl-50 { margin-left: 50px }
    .ml-xl-50 { margin-left: 50px }
    .mt-xl-8{margin-top: 8px}
}